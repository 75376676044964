import { Box, Flex, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { getRequiredDocuments } from '../../services/api';
import { MdnDevice } from '../../types/types';
import DocumentList, { DocumentDetails } from './DocumentList';
import { DocumentFolders, INVOICE_NOTE } from './constants';

// TODO: Modify this after integration with MyPayments
interface Props {
    deviceDetails: MdnDevice;
}

const MyDocumentsSection: FC<Props> = ({ deviceDetails }) => {
    const { policyNumber = '', imei } = deviceDetails;

    const { data: documentList, isLoading } = useQuery<DocumentDetails[]>({
        queryKey: ['document', policyNumber],
        queryFn: () => getRequiredDocuments(policyNumber),
        enabled: !!policyNumber,
    });

    return (
        <Box h={'full'} id={'myasurion-portal-documents'} w={'full'}>
            <Stack direction="column" alignItems="center">
                <VStack w="full" px={5} gap={0}>
                    <Flex w="full" py={2.5} borderBottom="1px solid #d5d6da">
                        <Text fontSize="sm" color="#9ca3af">
                            {INVOICE_NOTE}
                        </Text>
                    </Flex>
                    <Flex direction="column" w="full" alignItems="left">
                        {DocumentFolders.map((folder, idx) => (
                            <Flex
                                direction="column"
                                w="full"
                                alignItems="left"
                                key={`mydocuments-folder-${idx}`}
                            >
                                <Text fontSize="2xl" mt={5}>
                                    {folder.label}
                                </Text>
                                {isLoading ? (
                                    <Spinner />
                                ) : (
                                    <DocumentList
                                        documentList={documentList ?? []}
                                        selectedFolder={DocumentFolders[idx]}
                                        imei={imei}
                                    />
                                )}
                            </Flex>
                        ))}
                    </Flex>
                </VStack>
            </Stack>
        </Box>
    );
};

export default MyDocumentsSection;
