import { getCarrierByDomain, getTypeByDomain, getUrlByTypeAndEnv } from '@MGPD/myasurion-shared';
import { TextScript } from '@MGPD/myasurion-shared/components/localization';
import {
    AccountInfo,
    useAccount,
    useIsDisabledFeature,
    useSessionStorage,
} from '@MGPD/myasurion-shared/hooks';
import { useConfig } from '@MGPD/myasurion-shared/modules/config';
import { Mixpanel } from '@MGPD/myasurion-shared/utils/mixpanel';
import { ArrowForwardIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    HStack,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Spinner,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { MouseEvent, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { smartScanTokenUrlKey } from '../modules/config/constants';
import { DeviceSelectorProps, MdnDevice } from '../types/types';
import { formatMdnString, millisToDate, processContentfulImageToWebp } from '../utils';
import DynamicDeviceCard, { DeviceCardImageElement } from '../wip/DynamicDeviceCard';
import { TextBold } from './ReusableComponents';

const buttonText = (
    t: 'byod' | 'ppe' | 'default',
    cs?: 'draft' | 'completed' | 'cancelled' | 'submitted' | 'approved' | 'backorder'
) => {
    if (t?.toLowerCase() === 'byod' || t?.toLowerCase() === 'ppe') {
        return (
            <TextScript
                text={'Start device assessment'}
                translationKey={'DeviceSelector/StartDeviceAssessment'}
            />
        );
    } else {
        const processClaimStatusTextKey = (
            claimStatus:
                | 'draft'
                | 'completed'
                | 'cancelled'
                | 'submitted'
                | 'approved'
                | 'backorder'
                | undefined
        ) => {
            let claimStatusText = 'Update Device Details',
                claimStatusKey = 'UpdateDeviceDetails';
            if (claimStatus === 'draft') {
                claimStatusText = 'Manage Request';
                claimStatusKey = 'ResumeRequest';
            }

            if (
                claimStatus === 'submitted' ||
                claimStatus === 'approved' ||
                claimStatus === 'backorder'
            ) {
                claimStatusText = 'Manage Request';
                claimStatusKey = 'ManageRequest';
            }

            if (claimStatus === 'completed' || claimStatus === 'cancelled') {
                claimStatusText = 'Update Device Details';
                claimStatusKey = 'UpdateDeviceDetails';
            }

            return {
                claimStatusText,
                claimStatusKey,
            };
        };
        const { claimStatusText, claimStatusKey } = processClaimStatusTextKey(cs);
        return (
            <TextScript
                text={claimStatusText}
                translationKey={`DeviceSelector/${claimStatusKey}`}
            />
        );
    }
};
export const DeviceCardDetailsElement = ({
    type = 'default',
    status,
    // showMore,
    // selectedValue,
    serviceRequestBtn,
    isLoading,
    agreementClaimStatus,
    isUpdateDeviceInProgress,
    isAgreementSuspended,
    incidentType,
}: // handleShowMore,
// isLoading,
{
    type: 'ppe' | 'byod' | 'default';
    status?: 'pending' | 'under review' | 'approved' | 'processing';
    showMore: boolean;
    selectedValue?: MdnDevice;
    serviceRequestBtn: any;
    handleShowMore: () => void;
    isLoading?: boolean;
    agreementClaimStatus?:
        | 'draft'
        | 'completed'
        | 'cancelled'
        | 'submitted'
        | 'approved'
        | 'backorder';
    isUpdateDeviceInProgress?: boolean;
    isAgreementSuspended?: boolean;
    incidentType?: string;
}) => {
    // const { data } = useConfig();
    // const { theme } = data;
    // const { colors } = theme;
    const [smartScanTokenUrl] = useSessionStorage(smartScanTokenUrlKey, '');
    const getSmartScanToken: string = smartScanTokenUrl;
    const { onOpen, isOpen, onClose } = useDisclosure();
    const carrier = getCarrierByDomain();
    const portalType = getTypeByDomain();

    const onClickHandler = ({
        claimStatus,
        onModalOpen,
    }: {
        event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>;
        claimStatus?: 'draft' | 'completed' | 'cancelled' | 'submitted' | 'approved' | 'backorder';
        onModalOpen?: () => void;
    }) => {
        if (type.toLowerCase() === 'ppe' && getSmartScanToken) {
            window.location.href = getSmartScanToken;
        }

        if (type?.toLowerCase() !== 'byod' && type?.toLowerCase() !== 'ppe') {
            if (claimStatus === 'draft') {
                if (
                    incidentType &&
                    ((portalType === 'device_protection' &&
                        incidentType?.toLowerCase() !== 'swap') ||
                        (portalType === 'repair' && incidentType?.toLowerCase() === 'swap'))
                ) {
                    onModalOpen && onModalOpen();
                } else {
                    window.location.href = '/swap/myplansandservicerequests';
                }
            } else if (
                claimStatus === 'submitted' ||
                claimStatus === 'approved' ||
                claimStatus === 'backorder'
            ) {
                if (
                    incidentType &&
                    ((portalType === 'device_protection' &&
                        incidentType?.toLowerCase() !== 'swap') ||
                        (portalType === 'repair' && incidentType?.toLowerCase() === 'swap'))
                ) {
                    onModalOpen && onModalOpen();
                } else {
                    window.location.href = '/swap/myplansandservicerequests';
                }
            } else {
                window.location.href = '/swap/updateDevice';
            }
        }
    };

    return (
        <>
            {status && status !== 'pending' && (
                <Box></Box>
                // <Box w="full" px={4} py={3} mb={2} bg="#F0F0F5">
                //     <TextBold>
                //         {status === 'processing' ? '<Pick-up/Delivery>' : 'SR submitted'} on{' '}
                //         {'MMM DD, YYYY '}
                //     </TextBold>
                //     <Text>
                //         {status === 'approved' || status === 'processing'
                //             ? 'Message goes here... Our courier will come to <pick-up/return/deliver> your device between 3pm–5pm today.'
                //             : 'Message goes here...'}
                //     </Text>
                // </Box>
            )}
            {status && status === 'under review' && (
                <Box w="full" px={4} py={3} mb={2} bg="#F0F0F5">
                    {status === 'under review' && (
                        <TextBold>Device Assessment is being reviewed</TextBold>
                    )}
                    {status === 'under review' && (
                        <Text>
                            Our team is reviewing your submission and will revert with a decision
                            shortly
                        </Text>
                    )}
                </Box>
            )}
            {/* {showMore && status === 'pending' && (
                <AdditionalDeviceInfoPanel
                    serviceRequestBtn={serviceRequestBtn}
                    selectedValue={selectedValue}
                    type={type}
                />
            )}
            {showMore && type === 'default' && (
                <AdditionalDeviceInfoPanel
                    serviceRequestBtn={serviceRequestBtn}
                    selectedValue={selectedValue}
                    type={type}
                />
            )} */}

            <Box w="full" mt="2" mb="4" p={4}>
                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <ModalCloseButton />
                        </ModalHeader>
                        <ModalBody>
                            <Text fontWeight="bold">
                                <TextScript
                                    text={`${
                                        incidentType?.toLowerCase() === 'swap' ? 'Swap' : 'Repair'
                                    } Request in Progress`}
                                    translationKey={`DeviceSelector/Popup_${incidentType}_ModalHeader`}
                                />
                            </Text>
                            <Text>
                                <TextScript
                                    text={`You have an existing ${
                                        incidentType?.toLowerCase() === 'swap' ? 'swap' : 'repair'
                                    } request which is in progress. To manage this, please login to`}
                                    translationKey={`DeviceSelector/Popup_${incidentType}_ModalBody`}
                                />{' '}
                                <Link
                                    href={
                                        getUrlByTypeAndEnv(
                                            carrier,
                                            incidentType?.toLowerCase() === 'swap'
                                                ? 'device_protection'
                                                : 'repair',
                                            import.meta.env.VITE_ENV
                                        ) + '/swap/myplansandservicerequests'
                                    }
                                    isExternal={
                                        (portalType === 'device_protection' &&
                                            incidentType?.toLowerCase() !== 'swap') ||
                                        (portalType === 'repair' && incidentType === 'swap')
                                    }
                                    textDecoration="underline"
                                >
                                    <TextScript
                                        text={
                                            incidentType?.toLowerCase() === 'swap'
                                                ? 'Asurion TechCare'
                                                : 'Repair Service'
                                        }
                                        translationKey={`DeviceSelector/${
                                            incidentType?.toLowerCase() === 'swap'
                                                ? 'device_protection'
                                                : 'repair'
                                        }-Device care`}
                                    />
                                </Link>
                            </Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Skeleton isLoaded={!isLoading}>
                    <Button
                        {...serviceRequestBtn}
                        onClick={(e) => {
                            if (
                                (!agreementClaimStatus || agreementClaimStatus === 'cancelled') &&
                                isUpdateDeviceInProgress
                            ) {
                                e.preventDefault();
                                return;
                            }
                            onClickHandler({
                                event: e,
                                claimStatus: agreementClaimStatus,
                                onModalOpen: onOpen,
                            });
                        }}
                        isDisabled={
                            isAgreementSuspended ||
                            ((!agreementClaimStatus || agreementClaimStatus === 'cancelled') &&
                                isUpdateDeviceInProgress)
                        }
                    >
                        {buttonText(type, agreementClaimStatus)}
                    </Button>
                </Skeleton>
            </Box>
            {/* {selectedValue && status !== 'under review' && (
                <Box
                    w="full"
                    textAlign="center"
                    py={3}
                    display={'flex'}
                    flexFlow="column nowrap"
                    alignItems="center"
                >
                    <Skeleton isLoaded={!isLoading} w="max-content">
                        <Text
                            onClick={handleShowMore}
                            fontWeight="bold"
                            textDecor="underline"
                            color={colors.accent}
                        >
                            {showMore ? 'Hide Details' : 'More Details'}
                        </Text>
                    </Skeleton>
                </Box>
            )} */}
        </>
    );
};

// @DevNote
// The Plan:
// If getDevices return [{ mdn: "0002", index: "1"},{ mdn: "0001", index: "0"}, { mdn: "0002", index: "0"}]
// This should return   [{ mdn: "0002", index: "1"},{ mdn: "0002", index: "0"}, { mdn: "0001", index: "0"}]
// Solution => return MDN order while sorting through indices
const customDeviceSort = (a: MdnDevice, b: MdnDevice) => {
    // if (a?.mdn < b?.mdn) return -1;
    // if (a?.mdn > b?.mdn) return 1;
    // if (a?.index && b?.index) {
    //     if (a?.index < b.index) return -1;
    //     if (a?.index > b.index) return 1;
    // }
    // return 0;
    if (a?.mdn === b?.mdn) {
        if (a?.index && b?.index) {
            return a.index - b.index;
        }
    }
    return 0;
};

const DeviceSelector = ({
    selectedValue,
    setSelectedValue,
    viewAllDeviceText,
    deviceList,
    isLoading,
    isAgreementProcessing = false,
    hasUpgrade = false,
    activeSRNumber,
    agreementClaimStatus,
    isAgreementSuspended,
    isDocuments = false,
    isUpdateDeviceInProgress,
    incidentType,
}: DeviceSelectorProps) => {
    // images, titles and other branding should be coming from useConfig of shared
    const { data } = useConfig();
    const { theme } = data;
    const { colors } = theme;
    const { serviceRequestBtn } = theme?.components || {};
    const queryClient = useQueryClient();
    const [showMore, setShowMore] = useState<boolean>(false);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState<number>(0);
    const getDevicesData: MdnDevice[] = queryClient.getQueryData(['getDevicesData']) ?? deviceList;

    // const getSmartScanToken: string = queryClient.getQueryData(['smartScanToken']) ?? '';

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleMdnChange = (index: number) => {
        setShowMore(false);
        const selectedMdnDevice = deviceList
            ?.sort(customDeviceSort)
            ?.filter((e, i, s) => i === s.findIndex((fmdn) => fmdn.mdn === e.mdn))[index];
        setSelectedValue(selectedMdnDevice);
        // setSelectedDeviceIndex(index);
    };

    const handleDeviceChange = (index: number) => {
        setShowMore(false);
        // const selectedMdn = deviceList
        //     ?.sort(customDeviceSort)
        //     ?.filter((e, i, s) => i === s.findIndex((fmdn) => fmdn.mdn === e.mdn));
        const selectedDevice = deviceList
            .filter((fdl) => fdl?.mdn === selectedValue?.mdn)
            .find((fd) => Number(fd?.index ?? 0) === index);
        setSelectedValue(selectedDevice);
    };

    const { devices }: Record<string, any> = theme;
    const [account] = useAccount<AccountInfo>({ profileId: '', userId: '' });
    const isViewAllPlansDisabled = useIsDisabledFeature('portal', 'btnViewAllPlans');
    useEffect(() => {
        // const selectedDeviceIndex: number = deviceList.findIndex(
        //     (device: MdnDevice) => selectedValue === device
        // );
        const deviceIndex: number = deviceList
            ?.sort(customDeviceSort)
            ?.filter((e, i, s) => i === s.findIndex((fmdn) => fmdn?.mdn === e?.mdn))
            ?.findIndex((device: MdnDevice) => selectedValue?.mdn === device.mdn);
        setSelectedDeviceIndex(deviceIndex);
        setShowMore(selectedValue?.type && selectedValue.status === 'pending' ? true : false);
    }, [selectedValue?.type, deviceList]);
    // console.log('device selector', selectedValue);

    // const isSrDetailsEmpty: boolean = selectedValue?.srDetails
    //     ? Object.keys(selectedValue.srDetails).length === 0
    //     : false;

    return (
        <Box id={'myasurion-portal-deviceselector'} w={{ base: 'full' }} p={0} alignSelf={'start'}>
            <HStack flex="true" spacing={0}>
                <HStack w="full" borderBottom={`2px solid #000`}>
                    <HStack
                        bg="black"
                        p={5}
                        flex={2}
                        justifyContent={{ base: 'center', lg: 'start' }}
                        alignContent="center"
                        borderBottom={`2px solid #000`}
                        borderTopRadius={{ base: 0, lg: '8px' }}
                    >
                        <Skeleton isLoaded={!isLoading} w={isLoading ? '133px' : 'auto'}>
                            <Menu>
                                <MenuButton
                                    defaultValue={selectedDeviceIndex}
                                    placeholder="Select option"
                                    color="white"
                                    fontWeight="bold"
                                    textDecoration="underline"
                                    width="auto"
                                    disabled={
                                        !(
                                            selectedValue &&
                                            selectedValue.mdn &&
                                            !isLoading &&
                                            !isViewAllPlansDisabled
                                        ) //TODO: change feature flag
                                    }
                                >
                                    {selectedValue && selectedValue.mdn && !isLoading ? (
                                        formatMdnString(
                                            `${
                                                selectedValue?.callingCode
                                                    ? `(${selectedValue.callingCode}) `
                                                    : ''
                                            }${selectedValue?.mdn}`
                                        )
                                    ) : (
                                        <Spinner />
                                    )}
                                    {!isViewAllPlansDisabled && //TODO: change feature flag
                                        selectedValue &&
                                        selectedValue.mdn &&
                                        !isLoading &&
                                        getDevicesData
                                            ?.sort(customDeviceSort)
                                            ?.filter(
                                                (e, i, s) =>
                                                    i === s.findIndex((fmdn) => fmdn.mdn === e.mdn)
                                            )?.length > 1 && <ChevronDownIcon boxSize="18px" />}
                                </MenuButton>
                                {Array.isArray(getDevicesData) &&
                                    getDevicesData
                                        ?.sort(customDeviceSort)
                                        ?.filter(
                                            (e, i, s) =>
                                                i === s.findIndex((fmdn) => fmdn.mdn === e.mdn)
                                        )?.length > 1 && (
                                        <MenuList
                                            zIndex={
                                                selectedValue?.type?.toLowerCase() === 'ppe'
                                                    ? '999997'
                                                    : '99'
                                            }
                                        >
                                            {Array.isArray(getDevicesData) &&
                                                getDevicesData
                                                    ?.sort(customDeviceSort)
                                                    ?.filter(
                                                        (e, i, s) =>
                                                            i ===
                                                            s.findIndex(
                                                                (fmdn) => fmdn.mdn === e.mdn
                                                            )
                                                    )
                                                    ?.map((device, index) => (
                                                        <MenuItem
                                                            key={`menu-item-devicelist-${index}`}
                                                            value={index}
                                                            onClick={() => handleMdnChange(index)}
                                                            style={
                                                                device.mdn === selectedValue?.mdn
                                                                    ? {
                                                                          background:
                                                                              colors.primary,
                                                                          color: 'black',
                                                                      }
                                                                    : {}
                                                            }
                                                        >
                                                            {formatMdnString(
                                                                `${
                                                                    device.callingCode
                                                                        ? `(${device.callingCode}) `
                                                                        : ''
                                                                }${device.mdn}`
                                                            )}
                                                        </MenuItem>
                                                    ))}
                                        </MenuList>
                                    )}
                            </Menu>
                        </Skeleton>
                    </HStack>
                    {!isViewAllPlansDisabled && !isDocuments && (
                        <HStack
                            bg="white"
                            p={5}
                            flex={2}
                            justifyContent="end"
                            alignContent="center"
                            borderTopRadius={{ base: 0, lg: '8px' }}
                            w="full"
                        >
                            <Skeleton isLoaded={!isLoading} minH="29px">
                                <Text
                                    as={Link}
                                    href={'/portal/plan'}
                                    onClick={() =>
                                        Mixpanel.sendMixpanelEvent(
                                            'ASMA_Portal_DeviceSelector_ClickPlanDetailsRedirect',
                                            { userId: account.userId }
                                        )
                                    }
                                    // href={'/swap/myplansandservicerequests'}
                                    color={colors.accent}
                                    textDecor="none"
                                    fontWeight={700}
                                    _selected={{
                                        color: 'white',
                                        bg: 'black',
                                        textDecor: 'none',
                                    }}
                                >
                                    <TextScript
                                        text={
                                            selectedValue?.status === 'approved'
                                                ? 'Plan details'
                                                : viewAllDeviceText ?? ''
                                        }
                                        translationKey={'DeviceSelector/viewAllPlansText'}
                                    />
                                    <ArrowForwardIcon ml={2} boxSize="16px" />
                                </Text>
                            </Skeleton>
                        </HStack>
                    )}
                </HStack>
            </HStack>
            <HStack p={{ base: 4, lg: 0 }} justifyContent="center">
                <Box
                    w="100%"
                    maxW={{ base: '', sm: '345px', lg: 'full' }}
                    borderRadius={{ base: 8, lg: 0 }}
                    zIndex={selectedValue?.type?.toLowerCase() === 'ppe' ? '99999' : 'unset'}
                    background={'white'}
                >
                    <Swiper
                        navigation={{
                            enabled: true,
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        modules={[Pagination, EffectFade, Navigation]}
                        effect="fade"
                        fadeEffect={{ crossFade: true }}
                        onNavigationNext={(e) => handleDeviceChange(e?.activeIndex)}
                        onNavigationPrev={(e) => handleDeviceChange(e?.activeIndex)}
                    >
                        {Array.isArray(getDevicesData) &&
                            getDevicesData
                                ?.filter((fgdd) => fgdd.mdn === selectedValue?.mdn)
                                ?.map((mgdd, mgddi) => {
                                    selectedValue = mgdd;
                                    return (
                                        <SwiperSlide key={`${mgdd?.agreementId}-${mgddi}`}>
                                            <DynamicDeviceCard
                                                isLoading={isLoading}
                                                plan={selectedValue?.plan || ''}
                                                mdn={`${
                                                    selectedValue?.callingCode
                                                        ? `(${selectedValue?.callingCode}) `
                                                        : ''
                                                }${selectedValue?.mdn}`}
                                                model={selectedValue?.model || ''}
                                                imei={selectedValue?.imei || ''}
                                                make={selectedValue?.make || ''}
                                                serial={selectedValue?.serialNumber || ''}
                                                warranty={`${
                                                    selectedValue?.startDate
                                                        ? millisToDate(
                                                              selectedValue?.startDate,
                                                              'zh-TW'
                                                          )
                                                        : ''
                                                } to ${
                                                    selectedValue?.endDate
                                                        ? millisToDate(
                                                              selectedValue?.endDate,
                                                              'zh-TW'
                                                          )
                                                        : ''
                                                }`}
                                                redirectUrl={selectedValue?.redirectUrl || ''}
                                                assetId={selectedValue?.assetId || ''}
                                                status={
                                                    isAgreementSuspended
                                                        ? 'suspended'
                                                        : agreementClaimStatus ||
                                                          (!selectedValue?.type
                                                              ? isAgreementProcessing
                                                                  ? 'processing'
                                                                  : selectedValue?.status
                                                              : selectedValue?.status)
                                                }
                                                type={selectedValue?.type || 'default'}
                                                imageEl={
                                                    <DeviceCardImageElement
                                                        imageUrl={
                                                            selectedValue?.imageUrl
                                                                ? processContentfulImageToWebp(
                                                                      selectedValue.imageUrl
                                                                  )
                                                                : processContentfulImageToWebp(
                                                                      devices.iphone13Pro
                                                                  )
                                                        }
                                                    />
                                                }
                                                detailsEl={
                                                    <DeviceCardDetailsElement
                                                        type={selectedValue?.type || 'default'}
                                                        showMore={showMore}
                                                        serviceRequestBtn={serviceRequestBtn}
                                                        handleShowMore={handleShowMore}
                                                        status={
                                                            !selectedValue?.type
                                                                ? isAgreementProcessing
                                                                    ? 'processing'
                                                                    : selectedValue?.status
                                                                : selectedValue?.status
                                                        }
                                                        selectedValue={selectedValue}
                                                        isLoading={isLoading}
                                                        agreementClaimStatus={agreementClaimStatus}
                                                        isUpdateDeviceInProgress={
                                                            isUpdateDeviceInProgress
                                                        }
                                                        isAgreementSuspended={isAgreementSuspended}
                                                        incidentType={incidentType}
                                                    />
                                                }
                                                showMoreDetails={true}
                                                viewAllDeviceText={viewAllDeviceText}
                                                hasUpgrade={hasUpgrade}
                                                activeSRNumber={activeSRNumber}
                                                isDocuments={isDocuments}
                                                isUpdateDeviceInProgress={isUpdateDeviceInProgress}
                                                isAgreementSuspended={isAgreementSuspended}
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                        <Box
                            className="swiper-button-next"
                            transform="rotateY(180deg)"
                            boxSize={'50px'}
                        />
                        <Box className="swiper-button-prev" boxSize={'50px'}></Box>
                    </Swiper>
                </Box>
            </HStack>
        </Box>
    );
};

export default DeviceSelector;
